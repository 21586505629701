var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"group checkbox sm:items-center flex",class:{
    '!pl-2': _vm.reverseLabel,
    checked: _vm.isChecked,
    disabled: _vm.disabled,
    'sm:w-max': _vm.compact,
    primaryColor: _vm.primaryColor,
  }},[_c('input',{ref:"checkbox",staticClass:"checkbox-input mt-1 sm:mt-0 w-max",class:{
      'large-only': _vm.largeOnly,
      primaryColor: _vm.primaryColor,
    },attrs:{"id":_vm.id,"type":"checkbox","disabled":_vm.disabled},domProps:{"checked":_vm.isChecked},on:{"change":function($event){$event.stopPropagation();return _vm.updateValue.apply(null, arguments)}}}),_c('label',{staticClass:"checkbox-label min-width-0 flex items-center mr-2 sm:mr-0",class:{
      'large-only': _vm.largeOnly,
      'checkbox-label--right-side': _vm.reverseLabel,
      'blue-scheme': _vm.scheme === _vm.SCHEMES.blue,
    },attrs:{"for":_vm.id}},[_c('div',{staticClass:"label-text flex group-hover:text-primary",class:[
        _vm.labelStyles,
        {
          'text-gray-800': _vm.isChecked,
          'text-gray-600': !_vm.isChecked,
          'text-small sm:text-sm': !_vm.largeOnly,
          'text-sm': _vm.largeOnly,
          'table-fixed table w-full': _vm.isTruncateLabel,
          'w-max': !_vm.isTruncateLabel,
        },
      ]},[(_vm.labelIcon === 'bookmark')?_c('BookmarkIcon',{staticClass:"bookmark-icon h-3 w-3 sm:h-4 sm:w-4 mr-1 sm:mr-6px mt-1 sm:mt-2px"}):_vm._e(),_c('span',{staticClass:"max-w-full",class:{
          'table-cell truncate': _vm.isTruncateLabel,
          'group-hover:text-primary': !_vm.disabledLabelHover && _vm.scheme === _vm.SCHEMES.primary,
          'group-hover:text-blue-400': _vm.scheme === _vm.SCHEMES.blue,
        }},[_vm._v(" "+_vm._s(_vm.label)+" ")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }