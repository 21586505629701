<template>
  <div class="dialog bg-defaultOverlay">
    <div class="dialog-container-default p-6 sm:px-12 sm:py-8 mx-6 sm:mx-0 w-full sm:w-687px">
      <CloseIcon
        @click="cancel"
        class="close-dialog-icon cursor-pointer animation-focus-default w-6 h-6 absolute top-4 right-4 sm:top-7 sm:right-7"
      ></CloseIcon>

      <h3 class="mb-6 text-0_8xl sm:text-2xl">{{ header }}</h3>

      <div class="flex flex-col items-center">
        <div
          v-click-outside="hideDragging"
          :class="[{ 'border-gray-600': dragging }, { 'border-gray-400': !dragging }]"
          @dragenter.prevent.stop="dragging = true"
          @dragover.prevent.stop="dragging = true"
          @dragleave.prevent.stop="dragging = false"
          @drop.prevent.stop="uploadImage($event)"
          @click="openFiles()"
          class="flex border-dashed border-2 rounded-md w-full"
        >
          <input
            id="fileInput"
            ref="fileInput"
            class="w-0 h-0 opacity-0 absolute"
            type="file"
            accept="image/*"
            @change="uploadImage($event)"
          />

          <div
            v-if="!file || (isCropped && file)"
            class="flex flex-col justify-center items-center w-full my-12 mx-auto"
          >
            <UploadImageIcon v-if="!file" class="w-14 h-14"></UploadImageIcon>

            <div v-if="isCropped && file" class="flex relative">
              <div
                @click="reset"
                class="animation-focus-default cursor-pointer absolute p-3px rounded-full bg-gray-900 -top-2 -right-2"
              >
                <CloseIcon class="remove-preview-icon w-3 h-3"></CloseIcon>
              </div>
              <img
                :src="croppedPreview"
                :style="{ width: croppedPreviewWidth + 'px', height: croppedPreviewHeight + 'px' }"
                class="rounded mx-auto"
              />
            </div>

            <div class="flex items-center mt-3">
              <label
                :class="{
                  'text-defaultInteractive bg-defaultInteractive-transparent hover:bg-defaultInteractive':
                    isColorDefault(),
                  'text-primary bg-primary-transparent hover:bg-primary': !isColorDefault(),
                }"
                class="animation-focus-default text-sm cursor-pointer mr-1 bg-opacity-20 hover:text-white font-semibold py-1 px-2 rounded-md"
              >
                Upload a file
              </label>
              <span class="hidden sm:flex text-sm font-semibold">or drag and drop</span>
            </div>
            <div class="flex justify-center text-sm sm:text-small text-gray-600 mt-1">
              PNG, JPG, GIF up to 10MB
            </div>
          </div>

          <div v-else-if="!isCropped && file" class="w-full pt-6 pb-7">
            <div :class="{ showOutline }">
              <vue-croppie
                ref="croppieRef"
                :customClass="`manage-upload-croppie ${isColorDefault() ? 'default-color' : ''}`"
                :enableOrientation="true"
                :enableResize="false"
                :viewport="{ width: viewPort.width, height: viewPort.height }"
                :boundary="{ width: boundary.width, height: boundary.height }"
              >
              </vue-croppie>
            </div>

            <div class="flex justify-center">
              <Button
                @onClick="crop()"
                color-custom
                class="h-40px border-primary"
                compact
                :type="isCropping ? 'primary' : 'secondary'"
                :disabled="isCropping"
                :loading="isCropping"
                :primary-full-size="false"
              >
                <span
                  :class="{
                    'text-defaultInteractive': isColorDefault(),
                    'text-primary': !isColorDefault(),
                  }"
                  class="flex items-center justify-center px-3 text-sm font-semibold"
                >
                  <CropIcon
                    :class="{ 'default-color': isColorDefault() }"
                    class="crop-icon w-5 h-5 mr-2"
                  ></CropIcon>
                  Crop
                </span>
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div class="w-full flex justify-end mt-6 sm:mt-8">
        <Button
          class="w-full sm:min-w-155px sm:w-155px sm:h-56px"
          type="primary"
          color-custom
          :disabled="!isCropped"
          :primaryFullSize="false"
          @onClick="upload()"
        >
          <span
            :class="{
              'text-white': file && croppedPreview,
              'text-gray-500': !file || !croppedPreview,
            }"
            class="text-sm sm:text-0_8xl font-semibold sm:font-normal"
          >
            Upload
          </span>
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue';
import CloseIcon from '@/assets/icon_close.svg';
import CropIcon from '@/assets/icon_crop.svg';
import UploadImageIcon from '@/assets/icon_upload_image.svg';
import { DEFAULT_COLOR } from '@/components/constants/groupColors';
import { mapGetters } from 'vuex';
import imageCompression from 'browser-image-compression';

export default {
  name: 'ManagePictureDialog',
  components: {
    Button,
    CloseIcon,
    CropIcon,
    UploadImageIcon,
  },
  props: {
    header: {
      type: String,
      default: 'Upload a Picture',
    },
    croppedPreviewWidth: {
      type: Number,
      default: 56,
    },
    croppedPreviewHeight: {
      type: Number,
      default: 56,
    },
    viewPort: {
      type: Object,
      default: () => ({
        width: 198,
        height: 198,
      }),
    },
    boundary: {
      type: Object,
      default: () => ({
        width: 233,
        height: 198,
      }),
    },
  },
  data() {
    return {
      isCropped: false,
      isCropping: false,
      croppedPreview: null,
      cropped: null,
      showOutline: false,
      dragging: false,
      canOpen: true,
      file: null,
      defaultColor: DEFAULT_COLOR,
    };
  },
  created() {
    document.querySelector('body').style.overflow = 'hidden';
  },
  computed: {
    ...mapGetters(['group']),
  },
  methods: {
    isColorDefault() {
      return this.group.primaryColor === this.defaultColor;
    },
    openFiles() {
      if (this.canOpen) {
        document.getElementById('fileInput').click();
      }
    },
    hideDragging() {
      this.dragging = false;
    },
    reset() {
      this.isCropped = false;
      this.showOutline = true;
      this.dragging = false;
      this.file = null;

      // prevent open on reset
      setTimeout(() => {
        this.canOpen = true;
      });
    },
    // choose from device
    uploadImage(e) {
      if (this.croppedPreview) {
        this.reset();
      }

      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      const reader = new FileReader();
      reader.onload = (e) => {
        this.$nextTick(() => {
          this.$refs.croppieRef.bind({
            url: e.target.result,
          });
        });
      };

      reader.readAsDataURL(files[0]);
      // eslint-disable-next-line
      this.file = files[0];
      this.canOpen = false;
      e.target.value = '';
    },
    // crop with vue-croppie
    async crop() {
      this.isCropping = true;
      const finalOptions = {
        type: 'blob',
        format: 'jpg',
        size: {
          width: 1200,
          height: 1200,
        },
        viewport: {
          width: 300,
          height: 300,
        },
        quality: 1,
      };

      const previewOptions = {
        type: 'base64',
        format: 'jpg',
        size: {
          width: 1200,
          height: 1200,
        },
        viewport: {
          width: 300,
          height: 300,
        },
        quality: 1,
      };
      const compressionOptions = {
        useWebWorker: true,
      };
      this.$refs.croppieRef.result(previewOptions, (output) => {
        this.croppedPreview = output;
      });
      this.$refs.croppieRef.result(finalOptions, (output) => {
        imageCompression(output, compressionOptions).then((compressedFile) => {
          this.cropped = compressedFile;
          this.isCropped = true;
          this.isCropping = false;
        });
      });
    },
    rotateImage() {
      this.$refs.croppieRef.rotate(-90);
    },
    upload() {
      document.querySelector('body').style.overflow = 'auto';
      this.$emit('upload', {
        cropped: this.cropped,
        preview: this.croppedPreview,
      });
    },
    cancel() {
      document.querySelector('body').style.overflow = 'auto';
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss">
.dialog {
  @apply fixed flex justify-center items-center inset-0 z-50;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  height: 100vh;
}

.dialog .dialog-container-default {
  @apply relative rounded bg-white shadow-drop;
  margin-top: -69px;
}

.croppie-container .cr-viewport,
.croppie-container .cr-resizer {
  border: none !important;
  box-shadow: 0 0 2000px 2000px rgba(255, 255, 255, 0.5) !important;

  &::after {
    @apply absolute top-0 left-0 w-full h-full transition-opacity duration-200 ease-out opacity-0;
    content: url("data:image/svg+xml,%3Csvg viewBox='0 0 500 500' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M500 0H0v459.01c13.56-5.04 29.21-8 47.22-8 114.65 0 119.25-50.44 121.99-80.54l.41-4.37c-46.3-30.92-77.33-87.72-77.33-152.68C92.3 115.43 162.9 36 250 36c87.1 0 157.7 79.43 157.7 177.42 0 64.96-31.03 121.76-77.32 152.68l.41 4.37c2.74 30.1 7.33 80.54 121.99 80.54 18 0 33.66 2.96 47.22 8V0z' fill='%23000' fill-opacity='.55'/%3E%3C/svg%3E");
  }
}

.showOutline > .croppie-container .cr-viewport {
  &::after {
    @apply opacity-100;
  }
}

.croppie-container .cr-slider-wrap {
  width: 270px;
}

.cr-slider {
  @apply relative appearance-none w-full;

  &::before {
    @apply absolute w-5 h-5 top-0 mr-6 pointer-events-none;
    right: 100%;
    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 8L14 8' stroke='%23AAACAF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }

  &::after {
    @apply absolute w-5 h-5 top-0 ml-6 pointer-events-none;
    left: 100%;
    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 2V14' stroke='%23AAACAF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M2 8L14 8' stroke='%23AAACAF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  }
}

.cr-slider:focus {
  @apply outline-none;
}

.cr-slider::-webkit-slider-runnable-track {
  height: 2px;
  @apply w-full cursor-pointer bg-gray-300 rounded transition-colors duration-200 ease-out;
}

.cr-slider::-moz-range-track {
  height: 2px;
  @apply w-full cursor-pointer bg-gray-300 rounded transition-colors duration-200 ease-out;
}

.cr-slider::-ms-track {
  height: 2px;
  @apply w-full cursor-pointer bg-gray-300 rounded transition-colors duration-200 ease-out;
}

.manage-upload-croppie {
  &.default-color {
    .cr-slider::-webkit-slider-thumb,
    .cr-slider::-moz-range-thumb,
    .cr-slider::-ms-thumb {
      @apply bg-defaultInteractive;
    }
  }

  .cr-slider::-webkit-slider-thumb {
    margin-top: -9px;
    border: 4px solid #ffffff;
    @apply w-5 h-5 rounded-full bg-primary cursor-pointer appearance-none shadow-md;
  }

  .cr-slider::-moz-range-thumb {
    margin-top: -9px;
    border: 4px solid #ffffff;
    @apply w-3 h-3 rounded-full bg-primary cursor-pointer appearance-none shadow-md;
  }

  .cr-slider::-ms-thumb {
    margin-top: -9px;
    border: 4px solid #ffffff;
    @apply w-3 h-3 rounded-full bg-primary cursor-pointer appearance-none shadow-md;
  }

  .cr-slider:focus::-webkit-slider-runnable-track {
    @apply bg-gray-400;
  }

  .cr-slider::-ms-fill-lower {
    @apply bg-orange-400;
  }

  .cr-slider::-ms-fill-upper {
    @apply bg-gray-500;
  }

  .cr-slider:focus::-ms-fill-lower {
    @apply bg-orange-400;
  }

  .cr-slider:focus::-ms-fill-upper {
    @apply bg-gray-500;
  }
}

.close-dialog-icon {
  &:hover {
    path {
      @apply stroke-gray-800;
    }
  }

  path {
    stroke: #c8cacd;
  }
}

.remove-preview-icon path {
  stroke: #ffffff;
}

.crop-icon {
  &.default-color {
    path {
      @apply stroke-defaultInteractive;
    }
  }

  path {
    @apply stroke-primary;
  }
}

.croppie-container .cr-viewport::after,
.croppie-container .cr-resizer::after {
  content: none !important;
}

@media all and (max-width: 576px) {
  .croppie-container .cr-slider-wrap {
    width: 164px;
  }

  .cr-slider {
    &::before {
      @apply mr-4;
    }

    &::after {
      @apply ml-4;
    }
  }
}
</style>
