<template>
  <div
    :class="{
      'justify-center': centerContainer,
      'border-red-600 border-2 rounded': invalid,
    }"
    class="flex"
  >
    <!--             -->
    <!-- INLINE FORM -->
    <!--             -->

    <div
      class="inline-flex flex-col items-center justify-center p-6 sm:p-4 lg:p-7 border-4 border-dashed border-transparent rounded bg-white shadow-drop transition-color duration-200 ease-out"
      :class="[{ 'w-full sm:w-auto': !isCropped }]"
    >
      <div class="cursor-pointer" :class="{ 'w-full sm:w-auto': !isCropped }">
        <div
          class="flex justify-between items-center transform bg-white rounded active:scale-95 transition-transform duration-200 ease-out"
          @click="showManagePictureDialog = true"
        >
          <template v-if="!isCropped">
            <div
              class="empty-state-container flex items-center justify-center rounded mx-auto max-h-293px w-full h-240px sm:w-212px sm:h-212px sm:max-w-212px bg-gray-100 lg:w-293px lg:h-293px lg:max-w-293px"
            >
              <div
                class="animation-focus-default w-100px h-100px lg:w-130px lg:h-130px flex items-center justify-center rounded-full bg-white shadow"
              >
                <ProfileEmptyIcon class="w-12 h-12 lg:w-14 lg:h-14"></ProfileEmptyIcon>
              </div>
            </div>
          </template>
          <template v-else>
            <img
              :src="croppedPreview"
              class="rounded mx-auto max-h-293px sm:w-212px sm:h-212px sm:max-w-212px lg:w-293px lg:h-293px lg:max-w-293px"
            />
          </template>
        </div>
      </div>

      <div
        v-if="showUnderPhotoText"
        @click="showManagePictureDialog = true"
        class="cursor-pointer mt-6 flex flex-col items-center justify-center text-center w-full animation-focus-default"
      >
        <h2
          class="flex items-center justify-center font-sans font-semibold w-full rounded border border-gray-300 text-sm text-gray-800 p-2"
        >
          <CameraIcon class="hidden lg:block w-4 h-4 mr-3"></CameraIcon>
          <template v-if="!isCropped"> Upload a Photo </template>
          <template v-else-if="isCropped && existingImage"> Change a Photo </template>
          <template v-else> Choose a New Photo </template>
        </h2>
      </div>

      <div
        v-if="showRemovePhoto && showUnderPhotoText && isCropped"
        @click="removePhotoDialog()"
        class="mt-4 text-sm text-gray-500 underline cursor-pointer animation-focus-default"
      >
        Remove
      </div>
    </div>

    <!-- todo: find instance and redesign -->
    <div class="flex flex-col" v-if="!showUnderPhotoText">
      <div class="flex flex-col ml-8 mb-5">
        <h2 class="font-bold text-lg text-gray-900">
          Add a Photo so your friends can recognize you
        </h2>
        <p class="text-gray-900">Or, you can add it later.</p>
      </div>

      <div class="ml-8">
        <div
          @click="showManagePictureDialog = true"
          class="inline-block font-bold leading-relaxed px-8 py-3 relative text-center border-2 border-solid appearance-none rounded cursor-pointer transform focus:outline-none active:scale-95 transition duration-200 ease bg-gray-700 text-white"
        >
          + Upload an Image
        </div>
      </div>
    </div>

    <!--                     -->
    <!-- DIALOG UPLOAD PHOTO -->
    <!--                     -->

    <ManagePictureDialog
      v-if="showManagePictureDialog"
      header="Upload a Photo"
      @cancel="hideManagePictureDialog()"
      @upload="uploadPicture($event)"
    ></ManagePictureDialog>

    <!--                     -->
    <!-- DIALOG REMOVE PHOTO -->
    <!--                     -->

    <transition name="component-fade" mode="out-in">
      <DialogDefault
        v-if="showRemovePhotoDialog"
        :icon="'delete'"
        :header="'Remove your photo'"
        :text="'Are you sure you want to delete your photo?'"
        :applyBtnText="'Remove'"
        @cancel="cancelRemovePhotoDialog()"
        @apply="removePhoto()"
      ></DialogDefault>
    </transition>
  </div>
</template>

<script>
import DialogDefault from '@/components/DialogDefault.vue';
import ManagePictureDialog from '@/components/ManagePictureDialog.vue';
import ProfileEmptyIcon from '@/assets/icon_profile.svg';
import CameraIcon from '@/assets/icon_camera.svg';

export default {
  name: 'PhotoUploadInlineForm',
  components: {
    DialogDefault,
    ManagePictureDialog,
    ProfileEmptyIcon,
    CameraIcon,
  },
  props: {
    /**
     * Existing profile picture source
     */
    existingImage: {
      default: null,
    },
    /**
     * Error state for photo upload
     */
    invalid: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Container should be flex
     */
    centerContainer: {
      default: false,
    },
    showUnderPhotoText: {
      type: Boolean,
      default: true,
    },
    showRemovePhoto: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      croppedPreview: null,
      cropped: null,
      isCropped: false,
      showRemovePhotoDialog: false,
      showManagePictureDialog: false,
    };
  },
  created() {
    if (this.existingImage) {
      this.croppedPreview = this.existingImage;
      this.isCropped = true;
    }
  },
  methods: {
    hideManagePictureDialog() {
      this.showManagePictureDialog = false;
    },
    uploadPicture({ cropped, preview }) {
      this.isCropped = true;
      this.croppedPreview = preview;
      this.hideManagePictureDialog();
      this.$emit('submitted', cropped);
    },
    removePhoto() {
      // todo: remove photo
      this.showRemovePhotoDialog = false;
      this.isCropped = false;
      this.croppedPreview = null;
      this.$emit('submitted', null);
    },
    cancelRemovePhotoDialog() {
      this.showRemovePhotoDialog = false;
    },
    removePhotoDialog() {
      this.showRemovePhotoDialog = true;
    },
  },
};
</script>

<style lang="scss"></style>
