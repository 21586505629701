<template>
  <div
    class="group checkbox sm:items-center flex"
    :class="{
      '!pl-2': reverseLabel,
      checked: isChecked,
      disabled: disabled,
      'sm:w-max': compact,
      primaryColor: primaryColor,
    }"
  >
    <input
      @change.stop="updateValue"
      :id="id"
      type="checkbox"
      ref="checkbox"
      class="checkbox-input mt-1 sm:mt-0 w-max"
      :class="{
        'large-only': largeOnly,
        primaryColor: primaryColor,
      }"
      :checked="isChecked"
      :disabled="disabled"
    />
    <label
      class="checkbox-label min-width-0 flex items-center mr-2 sm:mr-0"
      :class="{
        'large-only': largeOnly,
        'checkbox-label--right-side': reverseLabel,
        'blue-scheme': scheme === SCHEMES.blue,
      }"
      :for="id"
    >
      <div
        :class="[
          labelStyles,
          {
            'text-gray-800': isChecked,
            'text-gray-600': !isChecked,
            'text-small sm:text-sm': !largeOnly,
            'text-sm': largeOnly,
            'table-fixed table w-full': isTruncateLabel,
            'w-max': !isTruncateLabel,
          },
        ]"
        class="label-text flex group-hover:text-primary"
      >
        <BookmarkIcon
          class="bookmark-icon h-3 w-3 sm:h-4 sm:w-4 mr-1 sm:mr-6px mt-1 sm:mt-2px"
          v-if="labelIcon === 'bookmark'"
        />
        <span
          class="max-w-full"
          :class="{
            'table-cell truncate': isTruncateLabel,
            'group-hover:text-primary': !disabledLabelHover && scheme === SCHEMES.primary,
            'group-hover:text-blue-400': scheme === SCHEMES.blue,
          }"
        >
          {{ label }}
        </span>
      </div>
    </label>
  </div>
</template>

<script>
import BookmarkIcon from '@/assets/icon_bookmark.svg';

const SCHEMES = {
  primary: 'primary',
  blue: 'blue',
};

export default {
  name: 'Checkbox',
  components: {
    BookmarkIcon,
  },
  props: {
    scheme: {
      type: String,
      default: SCHEMES.primary,
    },
    /**
     * id of the input
     */
    id: {
      type: String,
    },
    /**
     * The value for the checkbox input
     */
    value: {
      type: String,
    },
    /**
     * This is used from v-model in place of using value
     */
    checked: {},
    reverseLabel: {
      type: Boolean,
      default: false,
    },
    /**
     * The text that will be displayed next to the checkbox
     */
    label: {
      type: String,
      required: true,
    },

    disabledLabelHover: {
      type: Boolean,
      default: false,
    },
    /**
     * The icon that will be displayed before the text
     */
    labelIcon: {
      type: String,
    },
    /**
     * Determines whether the checkbox is disabled or not
     */
    disabled: {
      type: Boolean,
    },
    /**
     * Custom classes for the label
     */
    labelStyles: {
      type: String,
      default: '',
    },
    largeOnly: {
      type: Boolean,
      default: false,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    primaryColor: {
      type: Boolean,
      default: false,
    },
    isTruncateLabel: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isChecked() {
      if (Array.isArray(this.checked)) {
        return this.checked.includes(this.value);
      }
      return this.checked;
    },
  },

  model: {
    prop: 'checked',
    event: 'change',
  },

  data() {
    return {
      SCHEMES,
    };
  },

  methods: {
    updateValue() {
      if (Array.isArray(this.checked)) {
        const checkedItems = this.checked.slice();
        if (checkedItems.indexOf(this.value) !== -1) {
          checkedItems.splice(checkedItems.indexOf(this.value), 1);
        } else {
          checkedItems.push(this.value);
        }
        this.$emit('change', checkedItems);
      } else {
        this.$emit('change', !this.checked);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox {
  @apply flex relative pl-7 h-6;
  min-height: 1.5rem;
  width: 100%;
  max-width: calc(100% - 2.2rem);

  &.primaryColor {
    &:not(.checked):not(.disabled):hover {
      .checkbox-label::after {
        @apply lg:border-primary;
      }
    }
  }

  &:not(.checked):not(.disabled):hover {
    .checkbox-label:before {
      @apply border-gray-800;
    }
  }
}

.checkbox-input {
  &.large-only {
    @apply w-5 h-5;
  }

  @apply cursor-pointer absolute z-10 m-0 opacity-0 w-4 sm:w-6 h-4 sm:h-6 top-0 left-0;
}

.bookmark-icon {
  fill: #009765;
}

.checkbox-label {
  &.large-only {
    @apply pl-3;

    &::before {
      @apply w-6 h-6 rounded-sm;
    }
  }

  @apply mb-0 cursor-pointer pl-0 sm:pl-2 text-sm text-gray-500;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  transition: color 0.2s ease;

  &::before {
    @apply absolute w-5 h-5 sm:w-6 sm:h-6 top-0 left-0 border border-gray-400 rounded bg-white shadow-md;
    content: '';
    transition: border-color 0.2s ease, background-color 0.2s ease;
  }

  &::after {
    @apply absolute bg-transparent border-b-2 border-l-2 border-transparent;
    width: 14px;
    height: 9px;
    transform: rotate(-43deg);
    content: '';
    left: 5px;
    top: 5px;
  }
}

.checkbox-label.checkbox-label--right-side {
  @apply relative pr-2;

  &::before {
    @apply absolute w-4 h-4 top-1 sm:top-0.5 left-full border border-gray-400 rounded bg-white shadow-md;
    content: '';
    transition: border-color 0.2s ease, background-color 0.2s ease;
  }

  &::after {
    @apply absolute bg-transparent border-b-2 top-[6px] sm:top-[4px] border-l-2 border-transparent;
    width: 10px;
    height: 7px;
    transform: rotate(-43deg);
    content: '';
    left: calc(100% + 3px);
  }
}

.checkbox-input:checked + .checkbox-label {
  @apply text-gray-900;
}

.checkbox-input:checked + .checkbox-label::after {
  @apply border-white;
}

.checkbox-input:checked + .checkbox-label::before {
  @apply bg-primary border-transparent;
}

.checkbox-input:checked + .blue-scheme.checkbox-label::before {
  @apply bg-blue-400 border-transparent;
}

.checkbox-input.primaryColor:checked + .checkbox-label::before {
  @apply bg-primary border-transparent;
}

.checkbox-input:focus + .checkbox-label::before,
.checkbox-input:hover + .checkbox-label::before {
  @apply outline-none;
}

.checkbox-input:checked:focus + .checkbox-label::before,
.checkbox-input:checked:hover + .checkbox-label::before {
  @apply outline-none;
}

/* Disabled styles */
.checkbox-input:disabled {
  @apply cursor-default;
}

.checkbox-input:disabled + .checkbox-label {
  @apply cursor-default;

  &::before {
    @apply bg-gray-400 shadow-none cursor-default;
  }

  &::after {
    content: '';
  }
}

.checkbox-input:disabled:checked + .checkbox-label {
  @apply text-gray-600;

  &::before {
    @apply bg-gray-600 shadow-none cursor-default;
  }

  &::after {
    content: '';
  }
}

.checkbox-input:disabled:checked:hover + .checkbox-label::before {
  @apply cursor-default outline-none;
}

@media all and (max-width: 576px) {
  .checkbox-label:not(.large-only, .checkbox-label--right-side) {
    &::before {
      margin-top: 2px;
    }
    &::after {
      margin-top: 6px;
      width: 10px;
      height: 7px;
      left: 5px;
      top: 0;
    }
  }
}
</style>
